<div class="ui grid">
  <div class="row">
    <div class="sixteen wide column">
      <h1 class="ui header">Nutzer verwalten:</h1>
    </div>
  </div>

  <div class="row">
    <div class="sixteen wide column">
      <div class="ui segment grid">
        <div class="fourteen wide column">
          <h3 class="ui header left aligned">Filter:</h3>
        </div>

        <div class="two wide column">
          <h3 class="ui right aligned header">{{ numberOfUsers }} Ergebnisse</h3>
        </div>

        <div class="five wide column">
          <label for="name">Name</label>
          <div class="ui action input" style="width: 100%">
            <input id="name" type="text" placeholder="Name..." [(ngModel)]="usersFilter.q"
                   (keydown.enter)="getUsers()">
            <button class="ui primary icon button" (click)="getUsers()">
              <i class="search icon"></i>
            </button>
          </div>
        </div>

        <div class="five wide column">
          <div class="field">
            <label for="role">Nutzerrolle:</label>
            <sui-select
              #kcGroupSelect
              id="role"
              class="selection"
              style="width: 100%"
              [options]="kcGroups"
              [isSearchable]="true"
              [(ngModel)]="usersFilter.groupId"
              labelField="name"
              valueField="id"
              placeholder="Alle anzeigen"
            >
              <button class="default text item" (click)="usersFilter.groupId = undefined">
                Alle anzeigen
              </button>
              @for (option of kcGroupSelect.filteredOptions; track option.id) {
                <sui-select-option [value]="option"></sui-select-option>
              }
            </sui-select>
          </div>
        </div>

        <div class="five wide column">
          <div class="field">
            <label for="institution">Institution:</label>
            <sui-select
              #institutionSelect
              id="institution"
              class="selection"
              style="width: 100%"
              [options]="institutions"
              [isSearchable]="true"
              [(ngModel)]="usersFilter.institutionId"
              labelField="name"
              valueField="_id"
              placeholder="Alle anzeigen"
            >
              <button class="default text item" (click)="usersFilter.institutionId = undefined">
                Alle anzeigen
              </button>
              @for (option of institutionSelect.filteredOptions; track option) {
                <sui-select-option [value]="option"></sui-select-option>
              }
            </sui-select>
          </div>
        </div>

        <div class="sixteen wide column" style="align-content: end">
          <button class="ui right floated primary button" (click)="sendMailToCurrentList()">
            E-Mail versenden
          </button>
          <button class="ui right floated primary button" (click)="exportUsers()">
            Daten als Excel herunterladen
          </button>
          <button class="ui right floated primary button" (click)="getUsers()">
            Nutzer filtern
          </button>
          <button class="ui right floated secondary button" (click)="resetFilter()">
            Zurücksetzen
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="sixteen wide column">
      <table class="ui selectable celled table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Institution</th>
          <th>Aktionen</th>
        </tr>
        </thead>
        <tbody>
          @for (user of users; track user) {
            <tr>
              <td>{{ user.firstname }} {{ user.lastname }}</td>
              <td>{{ user.abonnement?.email }}</td>
              <td>{{ user.institution?.name }}</td>
              <td>
                <button class="ui primary icon button" data-tooltip="Bearbeiten" (click)="editUser(user)">
                  <i class="edit icon"></i>
                </button>
                <button class="ui red icon button" data-tooltip="Löschen" (click)="deleteUser(user)">
                  <i class="trash icon"></i>
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>

  <!--PAGINATION-->
  <div class="centered row">
    <sui-pagination [maxSize]="5" [canRotate]="true" [hasEllipses]="true" [collectionSize]="pagination.collectionSize"
                    [pageSize]="pagination.pageSize" [hasNavigationLinks]="true" [hasBoundaryLinks]="true"
                    [(page)]="pagination.selectedPage" (pageChange)="handlePageChange()">
    </sui-pagination>
  </div>
</div>
