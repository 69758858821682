<div class="ui grid">
  <div class="sixteen wide column">
    <h1 class="ui header">{{ title }}</h1>
    <form class="ui form" [formGroup]="topicForm" (ngSubmit)="submit()">
      <!--NAME-->
      <div class="field required" [class.error]="topicForm.get('name')?.invalid">
        <label for="name">Name:</label>
        <input id="name" formControlName="name" type="text" name="name" placeholder="Name">
      </div>
      <!--DESCRIPTION-->
      <div class="field" [class.error]="topicForm.get('description')?.invalid">
        <label for="description">Beschreibung:</label>
        <input id="description" formControlName="description" type="text" name="description" placeholder="Beschreibung">
      </div>
      <!--KIND-->
      <div class="field" [class.error]="topicForm.get('categories')?.invalid">
        <label for="categories">Art:</label>
        <sui-select id="categories" class="selection"
                    formControlName="kind"
                    [options]="TOPIC_KINDS"
                    [isSearchable]="false"
                    #selectKind>
          @for (option of selectKind.filteredOptions; track option) {
            <sui-select-option [value]="option"></sui-select-option>
          }
        </sui-select>
      </div>
      <!--IS SUBJECTAREA-->
      <div class="field">
        <sui-checkbox class="toggle" formControlName="isSubjectArea" name="isSubjectArea">
          Ist eine Fachrichtung?
        </sui-checkbox>
      </div>
      <!--IS VISIBLE-->
      <div class="field">
        <sui-checkbox class="toggle" formControlName="isInvisible" name="isInvisible">
          Ist unsichtbar?
        </sui-checkbox>
      </div>
      <button class="ui primary button" type="submit" [disabled]="!topicForm.valid">Speichern</button>
    </form>
  </div>
</div>
