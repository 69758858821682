import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {TOPIC_KINDS} from '../../../../../../core/constants';
import {Topic} from 'src/app/core/models/topics/topics.model';
import {TopicsService} from '../../../../../../core/services/topics/topics.service';
import {ToastService} from "../../../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-topic-form',
  templateUrl: './topic-form.component.html',
  styleUrls: ['./topic-form.component.css']
})
export class TopicFormComponent implements OnInit {
  public title = 'Kategorie';
  public TOPIC_KINDS = TOPIC_KINDS;
  public isUpdatingTopic!: boolean;
  public isSubjectArea!: boolean;

  public topic: Topic = {_id: '', description: '', isInvisible: false, isSubjectArea: false, name: '', kind: ''};

  public topicForm!: UntypedFormGroup;
  public isSubjectAreaControl!: UntypedFormControl;
  public isInvisibleControl!: UntypedFormControl;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private topicsService: TopicsService,
    public modalService: SuiModalService,
    private router: Router,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.init();
    this.initForm();
  }

  private init(): void {
    const topicId = this.route.snapshot.paramMap.get('id');
    const isSubjectArea = this.route.snapshot.queryParamMap.get('isSubjectArea');

    if (topicId) {
      this.isUpdatingTopic = true;
      this.initTopic(topicId);
    }

    if (isSubjectArea) {
      this.isSubjectArea = true;
      this.topic.isSubjectArea = true;
      this.title = 'Fachrichtung';
    }

    if (this.route.snapshot.url.filter((url) => url.path === 'create').length > 0) {
      this.title += ' erstellen:';
    } else {
      this.title += ' bearbeiten:';
    }

    this.initForm();
  }

  private initTopic(topicId: string): void {
    this.topicsService.getOne(topicId).then((topicData) => {
      this.topic = topicData;
      this.initForm();
    });
  }

  private initForm(): void {
    this.isSubjectAreaControl = new UntypedFormControl(this.topic.isSubjectArea);
    this.isInvisibleControl = new UntypedFormControl(this.topic.isInvisible);

    this.topicForm = this.fb.group({
      name: [this.topic.name, Validators.required],
      description: [this.topic.description],
      isSubjectArea: this.isSubjectAreaControl,
      isInvisible: this.isInvisibleControl,
      kind: [this.topic.kind],
    });
  }

  public submit(): void {
    const type = this.isSubjectArea ? 'Fachrichtung' : 'Kategorie';

    if (this.isUpdatingTopic) {
      // update topic
      this.topicsService.updateOne(this.topicForm.value, this.topic._id).then((res) => {
        this.infoModalAndReroute(res, type, true);
      });
    } else {
      // add new topic
      this.topicsService.createOne(this.topicForm.value).then((res) => {
        this.infoModalAndReroute(res, type);
      });
    }
  }

  private infoModalAndReroute(res: any, type: string, edit?: boolean): void {
    let msg: string;
    if (res._id) {
      msg = 'Die ' + type + ' wurde erfolgreich ' + (edit ? 'bearbeitet' : 'erstellt.');
    } else {
      msg = 'Die ' + type + ' konnte nicht ' + (edit ? 'bearbeitet' : 'erstellt' + ' werden.');
    }

    this.toastService.success(type + (edit ? ' bearbeitet' : ' erstellt'), msg);

    if (this.isSubjectArea) {
      this.router.navigate(['/admin/subjectareas']);
    } else {
      this.router.navigate(['/admin/topics']);
    }
  }
}
