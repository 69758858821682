<div class="ui grid">
  <div class="row">
    <div class="sixteen wide column">
      <h1 class="ui header">Kontaktpersonen</h1>
    </div>
  </div>

  <div class="row">
    <div class="sixteen wide column">
      <div class="ui segment grid">
        <div class="fourteen wide column">
          <h3 class="ui header left aligned">Filter:</h3>
        </div>

        <div class="two wide column">
          <h3 class="ui right aligned header">{{pagination.collectionSize}} Ergebnisse</h3>
        </div>

        <div class="four wide column">
          <div>Name</div>
          <div class="ui action input" style="width: 100%">
            <input type="text" placeholder="Name..." [(ngModel)]="filterData.name"
                   (keydown.enter)="getContacts()">
            <button class="ui icon button" (click)="getContacts()">
              <i class="search icon search-icon"></i>
            </button>
          </div>
        </div>

        <div class="four wide column">
          <div class="field">
            <div>Institution:</div>
            <sui-select
              #institutionSelect
              id="institution" class="selection" style="width: 100%"
              placeholder="Alle anzeigen" [isSearchable]="true" [options]="institutions"
              labelField="name" valueField="_id"
              [(ngModel)]="filterData.institutionId"
            >
              <button class="default text item" (click)="filterData.institutionId = undefined">
                Alle anzeigen
              </button>
              @for (option of institutionSelect.filteredOptions; track option._id) {
                <sui-select-option [value]="option"></sui-select-option>
              }
            </sui-select>
          </div>
        </div>
        <div class="four wide column">
          <div class="field">
            <div>Art der Institution:</div>
            <sui-select
              #kindSelect
              id="institutionKind" class="selection" style="width: 100%"
              placeholder="Alle anzeigen" [isSearchable]="true" [options]="institutionKinds"
              [(ngModel)]="filterData.institutionKind"
            >
              <button class="default text item" (click)="filterData.institutionKind = undefined">
                Alle anzeigen
              </button>
              @for (option of kindSelect.filteredOptions; track option) {
                <sui-select-option [value]="option"></sui-select-option>
              }
            </sui-select>
          </div>
        </div>

        <div class="sixteen wide column" style="align-content: end">
          <button class="ui right floated primary button" (click)="sendMailToCurrentList()">
            E-Mail versenden
          </button>
          <button class="ui right floated primary button" (click)="exportContacts()">
            Daten als Excel herunterladen
          </button>
          <button class="ui right floated primary button" (click)="getContacts()">
            Kontaktpersonen filtern
          </button>
          <button class="ui right floated secondary button" (click)="clearFilter()">
            Zurücksetzen
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="sixteen wide column">
      <table class="ui celled table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email-Adresse</th>
          <th>Telefonnummer</th>
          <th>Institution</th>
          <th>Art der Institution</th>
        </tr>
        </thead>
        <tbody>
        @for (contact of contacts; track contact) {
          <tr>
            <td>{{ contact.name }}</td>
            <td>{{ contact.email }}</td>
            <td>{{ contact.phone }}</td>
            <td>{{ contact.institution.name }}</td>
            <td>{{ contact.institution.kind }}</td>
          </tr>
        }
        </tbody>
      </table>
    </div>
  </div>

  <!--PAGINATION-->
  <div class="centered row">
      <sui-pagination [maxSize]="5" [canRotate]="true" [hasEllipses]="true" [collectionSize]="pagination.collectionSize"
                      [pageSize]="pagination.pageSize" [hasNavigationLinks]="true" [hasBoundaryLinks]="true"
                      [(page)]="pagination.selectedPage" (pageChange)="handlePageChange()">
      </sui-pagination>
  </div>
</div>
