import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {optimizeFilter} from '../../utils/filter.helper';
import {environment} from '../../../../environments/environment';
import {AdminMail} from '../../models/admin-mail/admin-mail.model';

@Injectable({
  providedIn: 'root'
})
export class AdminMailsService {
  private adminMailsUrl = environment.baseURL + environment.adminMail;

  constructor(
    private http: HttpClient
  ) {
  }

  createOne(adminMail: AdminMail): Promise<any> {
    return this.http.post(this.adminMailsUrl, adminMail).toPromise();
  }

  getAll(queryParams?: any) {
    if (queryParams) {
      optimizeFilter(queryParams);
    }

    return this.http.get<{ docs: AdminMail[], total: number }>(this.adminMailsUrl, {params: queryParams}).toPromise();
  }

  getOne(id: string) {
    return this.http.get(this.adminMailsUrl, {params: {id}}).toPromise();
  }

  updateOne(id: string, adminMail: AdminMail): Promise<AdminMail> {
    return this.http.put<AdminMail>(`${this.adminMailsUrl}/${id}`, adminMail).toPromise();
  }

  deleteOne(id: string) {
    return this.http.delete<AdminMail>(`${this.adminMailsUrl}/${id}`).toPromise();
  }
}
