import {Component} from '@angular/core';

import {ComponentModalConfig, ModalSize, SuiModal} from '@angular-ex/semantic-ui';

import {User} from '../../../../core/models/users/users.model';
import {UserFormValues} from '../../../../shared/forms/user-profile-form/user-profile-form.component';
import {UserNewsletterFormValues} from '../../../../shared/forms/user-newsletter-form/user-newsletter-form.component';

interface IEditUserModalContext {
  title: string;
  editUser: User;
}

export interface EditUserValues {
  profile: UserFormValues;
  newsletter: UserNewsletterFormValues;
}

@Component({
  selector: 'app-edit-user-modal',
  templateUrl: './edit-user-modal.component.html',
  styleUrls: ['./edit-user-modal.component.css']
})
export class EditUserModalComponent {

  constructor(
    public modal: SuiModal<IEditUserModalContext, EditUserValues, void>
  ) {
  }
}

export class EditUserModal extends ComponentModalConfig<IEditUserModalContext, EditUserValues, void> {
  constructor(data: IEditUserModalContext, size = ModalSize.Small) {
    super(EditUserModalComponent, data);

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
