<table class="ui selectable celled table">
  <thead>
  <tr>
    <th>Name</th>
    <th>Beschreibung</th>
    @if (showKind) {
      <th>Art</th>
    }
    <th>Aktionen</th>
  </tr>
  </thead>
  <tbody>
    @for (topic of topics; track topic) {
      <tr>
        <td>{{ topic.name }}</td>
        <td>{{ topic.description }}</td>
        @if (showKind) {
          <td>{{ topic.kind }}</td>
        }
        <td>
          <a class="ui primary icon button" data-tooltip="Bearbeiten"
             [routerLink]="['/admin/topics/edit', topic._id]"
             [queryParams]="{isSubjectArea: 'true'}">
            <i class="edit icon"></i>
          </a>
          <button class="ui red icon button" data-tooltip="Löschen" (click)="delete(topic)">
            <i class="trash icon"></i>
          </button>
        </td>
      </tr>
    }
  </tbody>
</table>
