import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {Institution} from '../../../../core/models/institutions/institutions.model';
import {AlertModal} from '../../../../shared/modals/alert-modal/alert-modal.component';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';

@Component({
  selector: 'app-manage-institutions',
  templateUrl: './manage-institutions.component.html',
  styleUrls: ['./manage-institutions.component.css']
})
export class ManageInstitutionsComponent implements OnInit {
  public institutions: Institution[] = [];

  constructor(
    private institutionsService: InstitutionsService,
    private modalService: SuiModalService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getInstitutions();
  }

  private getInstitutions(): void {
    this.institutionsService.getAll().then((institutionsData) => {
      this.institutions = institutionsData.docs;
    });
  }

  public addInstitution(): void {
    this.router.navigate(['/admin/institutions/create']);
  }

  delete(inst: Institution) {
    this.modalService.open(new AlertModal(
      `Institution "${inst.name}" löschen?`,
      'Soll diese Institution wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!',
    )).onApprove(() => {
      this.institutionsService.deleteOne(inst._id).then(() => {
        this.institutions.splice(this.institutions.indexOf(inst), 1);
      });
    });
  }
}
