import {Router} from '@angular/router';
import {Component, OnInit} from '@angular/core';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {Topic} from '../../../../core/models/topics/topics.model';
import {TopicsFilter} from '../../../../core/models/topics/topics.filter';
import {TopicsService} from '../../../../core/services/topics/topics.service';

@Component({
  selector: 'app-manage-subjectareas',
  templateUrl: './manage-subjectareas.component.html',
  styleUrls: ['./manage-subjectareas.component.css']
})
export class ManageSubjectareasComponent implements OnInit {
  public subjectAreas: Topic[] = [];

  constructor(
    private topicsService: TopicsService,
    public modalService: SuiModalService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.getSubjectAreas();
  }

  private getSubjectAreas() {
    const queryParams: TopicsFilter = {
      isSubjectArea: 'true',
    };

    this.topicsService.getAll(queryParams).then((topicsData) => {
      this.subjectAreas = topicsData.docs;
    });
  }

  public addSubjectArea(): void {
    this.router.navigate(['/admin/topics/create'], {queryParams: {isSubjectArea: 'true'}});
  }
}
