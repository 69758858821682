import {Component, OnInit} from '@angular/core';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {User} from '../../../../core/models/users/users.model';
import {UsersFilter} from '../../../../core/models/users/users.filter';
import {UsersService} from '../../../../core/services/users/users.service';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {AlertModal} from '../../../../shared/modals/alert-modal/alert-modal.component';
import {KcAdminCliService} from '../../../../core/services/kc-admin-cli/kc-admin-cli.service';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {EditUserModal, EditUserValues} from '../../modals/edit-user-modal/edit-user-modal.component';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  // pagination
  public pagination = {
    collectionSize: 42,
    pageSize: 10,
    selectedPage: 1,
  };

  // data
  public users: User[] = [];
  public institutions: Institution[] = [];
  public numberOfUsers = 0;

  // filter
  public kcGroups!: KcGroup[];
  public usersFilter: UsersFilter = {};

  constructor(
    private usersService: UsersService,
    private institutionsService: InstitutionsService,
    public modalService: SuiModalService,
    private kcAdminService: KcAdminCliService,
    private kcAdminCliService: KcAdminCliService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.kcAdminCliService.getAllGroups().then((res: KcGroup[]) => {
      this.kcGroups = res.filter((group) => group.name !== 'User');
    });
    this.getUsers();
    this.getInstitutions();
  }

  public getUsers(onlyPageChange?: boolean): void {
    if (!onlyPageChange) {
      this.pagination.selectedPage = 1;
    }

    this.usersService.getAll({
      ...this.usersFilter,
      populate: ['institution'],
      selectedPage: this.pagination.selectedPage,
      pageSize: this.pagination.pageSize,
    }).then((usersData: { docs: User[], total: number }) => {
      this.users = usersData.docs;
      this.pagination.collectionSize = usersData.total;
      this.numberOfUsers = usersData.total;
    });
  }

  deleteUser(selectedUser: User) {
    this.modalService.open(new AlertModal(
      `Nutzer "${selectedUser.firstname} ${selectedUser.lastname}" löschen?`,
      'Soll dieser Nutzer wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!',
    )).onApprove(() => {
      Promise.all([
        this.usersService.deleteOne(selectedUser._id),
        this.kcAdminService.deleteUser(selectedUser.kcId),
      ]).then(() => {
        this.toastService.success('Nutzer gelöscht', `Der Nutzer "${selectedUser.firstname} ${selectedUser.lastname}" wurde erfolgreich gelöscht.`)
        this.getUsers();
      }, error => this.toastService.error('Nutzer nicht gelöscht', `Der Nutzer konnte nicht gelöscht werden.`, error));
    });
  }

  public handlePageChange(): void {
    this.getUsers(true);
  }

  editUser(selectedUser: User) {
    this.modalService.open(
      new EditUserModal({
        title: `"${selectedUser.firstname} ${selectedUser.lastname}" bearbeiten`,
        editUser: selectedUser
      })
    ).onApprove((editUserFormData: any) => {
      this.updateUser(selectedUser, editUserFormData);
    });
  }

  private async updateUser(user: User, editUserValues: EditUserValues) {
    const {_id, kcId} = user;

    try {
      // update fit user profile
      const {receiveInternalMessages, ...newsletter} = editUserValues.newsletter;
      const newUser = await this.usersService.updateOne(_id, {
        ...editUserValues.profile,
        receiveInternalMessages,
        abonnement: {
          ...user.abonnement,
          ...newsletter,
        },
      });
      this.users[this.users.findIndex(u => u._id === _id)] = newUser;

      if (user.email !== newUser.email) {
        // update user profile in keycloak
        const kcUser = await this.kcAdminService.getUser(kcId);
        kcUser.email = editUserValues.profile.email;
        kcUser.username = editUserValues.profile.email;
        await this.kcAdminService.updateOneUser(kcId, kcUser);
      }

      await this.kcAdminService.addUserToGroup(kcId, editUserValues.profile.role);

      this.toastService.success('Nutzer bearbeitet', `Der Nutzer "${newUser.email}" wurde erfolgreich bearbeitet.`);
    } catch (error) {
      this.toastService.error('Nutzer nicht bearbeitet', 'Der Nutzer konnte nicht bearbeitet werden.', error);
    }
  }

  public exportUsers() {
    this.usersService.exportUsers(this.usersFilter);
  }

  public async sendMailToCurrentList() {
    const mailUsers = await this.getMailUsers();
    document.location.assign('mailto:?bcc=' + this.createEmailDistributorString(mailUsers));
  }

  private createEmailDistributorString(receivingUsers: User[]): string {
    const mails = [];

    for (const receivingUser of receivingUsers) {
      if (receivingUser.abonnement && receivingUser.abonnement.email) {
        mails.push(receivingUser.abonnement.email);
      } else {
        mails.push(receivingUser.email);
      }
    }

    return mails.join(';');
  }

  private async getMailUsers(): Promise<User[]> {
    return this.usersService.getAll({
      ...this.usersFilter,
      select: ['email', 'abonnement.email'],
    }).then(usersData => {
      return usersData.docs;
    });
  }

  resetFilter() {
    this.usersFilter = {};
  }

  private getInstitutions() {
    this.institutionsService.getAll().then((res) => {
      this.institutions = res.docs;
    });
  }
}

interface KcGroup {
  id: string;
  name: string;
  path: string;
  subGroups: string[];
}
