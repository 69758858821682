import {Component, Input} from '@angular/core';

import {Topic} from 'src/app/core/models/topics/topics.model';
import {AlertModal} from '../../../../../../shared/modals/alert-modal/alert-modal.component';
import {SuiModalService} from '@angular-ex/semantic-ui';
import {TopicsService} from '../../../../../../core/services/topics/topics.service';

@Component({
  selector: 'app-topics-list',
  templateUrl: './topics-list.component.html',
  styleUrls: ['./topics-list.component.css']
})
export class TopicsListComponent {
  @Input() topics: Topic[] = [];
  @Input() showKind = true;

  constructor(
    private modalService: SuiModalService,
    private topicsService: TopicsService,
  ) {
  }

  delete(topic: Topic) {
    const type = topic.isSubjectArea ? 'Fachrichtung' : 'Kategorie';
    this.modalService.open(new AlertModal(
      `${type} "${topic.name}" löschen?`,
      `Soll diese ${type} wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!`,
    )).onApprove(() => {
      this.topicsService.deleteOne(topic._id).then(() => {
        this.topics.splice(this.topics.indexOf(topic), 1);
      });
    });
  }
}
