<div class="header">{{ modal.context.title }}</div>
<div class="content">
  <sui-tabset>
    <div class="ui top attached tabular menu">
      <a class="item" suiTabHeader="1">Profil</a>
      <a class="item" suiTabHeader="2">Newsletter</a>
    </div>
    <div class="ui bottom attached segment" suiTabContent="1">
      <app-user-profile-form
        #userForm
        [editUser]="modal.context.editUser"
        [admin]="true"
        (institutionChanged)="userNewsletterForm.setEmailValidators($event)"
      ></app-user-profile-form>
    </div>
    <div class="ui bottom attached segment" suiTabContent="2">
      <app-user-newsletter-form
        #userNewsletterForm
        [editUser]="modal.context.editUser"
        [admin]="true"
      ></app-user-newsletter-form>
      Letzte Email gesendet am: {{modal.context.editUser?.abonnement?.lastMailSend | date: 'dd.MM.yyyy HH:mm'}}
    </div>
  </sui-tabset>
</div>
<div class="actions">
  <button class="ui red button" (click)="modal.deny(undefined)">Abbrechen</button>
  <button class="ui green button"
          (click)="modal.approve({profile: userForm.submit(), newsletter: userNewsletterForm.submit()})"
          [disabled]="userForm.userForm.invalid || userNewsletterForm.newsletterForm.invalid">
    Speichern
  </button>
</div>
