import {Component, OnInit} from '@angular/core';

import {ViewContact} from '../../../../core/models/contacts/contacts.model';
import {ContactsFilter} from '../../../../core/models/contacts/contacts.filter';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {ContactsService} from '../../../../core/services/contacts/contacts.service';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';

@Component({
  selector: 'app-view-contacts',
  templateUrl: './view-contacts.component.html',
  styleUrls: ['./view-contacts.component.css']
})
export class ViewContactsComponent implements OnInit {
  // pagination
  public pagination = {
    collectionSize: 0,
    pageSize: 10,
    selectedPage: 1,
  };

  // data
  public institutions: Institution[] = [];
  institutionKinds: string[] = [];
  public contacts: ViewContact[] = [];

  // filter
  public filterData: ContactsFilter = {};

  constructor(
    private institutionsService: InstitutionsService,
    private contactsService: ContactsService,
  ) {
  }

  ngOnInit() {
    this.getInstitutions();
    this.getContacts();
  }

  public handlePageChange(): void {
    this.getContacts(true);
  }

  public clearFilter() {
    this.filterData = {};
    this.resetPagination();
    this.getContacts();
  }

  public getContacts(onlyPageChange?: boolean): void {
    if (!onlyPageChange) {
      this.pagination.selectedPage = 1;
    }

    this.contactsService.getAll({
      ...this.filterData,
      ...this.pagination,
    }).then((res) => {
      this.contacts = res.docs;
      this.pagination.collectionSize = res.total;
    });
  }

  public exportContacts() {
    this.contactsService.exportContacts(this.filterData);
  }

  public async sendMailToCurrentList() {
    const mailUsers = await this.contactsService.getAll(this.filterData);
    const mails = mailUsers.docs.filter(c => c.email).map(c => c.email);
    open('mailto:?bcc=' + mails.join(';'));
  }

  private getInstitutions(): void {
    this.institutionsService.getAll().then((res) => {
      this.institutions = res.docs;
      this.institutionKinds = [...new Set(this.institutions.map(i => i.kind))];
    });
  }

  private resetPagination() {
    this.pagination.pageSize = 10;
    this.pagination.selectedPage = 1;
  }
}
