<div class="ui grid">
  <div class="row">
    <div class="sixteen wide column">
      <h1 class="ui header">Institution erstellen: </h1>
      <app-institution-form #institutionFormComponent></app-institution-form>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <button class="ui primary button" (click)="saveInstitution()"
              [disabled]="!institutionFormComponent.institutionForm.valid">
        Speichern
      </button>
    </div>
  </div>
</div>
