<div class="ui grid">
  <div class="row">
    <div class="fifteen wide column">
      <h1 class="ui header">Kategorien verwalten: </h1>
    </div>
    <div class="one wide column">
      <div class="ui right floated left labeled icon primary button" (click)="addTopic()" (keydown)="addTopic()" tabindex="-1">
        <i class="plus square icon"></i>
        Erstellen
      </div>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <app-topics-list [topics]="topics"></app-topics-list>
    </div>
  </div>
</div>
