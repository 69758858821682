import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {Subscription} from 'rxjs';
import {Institution} from '../../../../core/models/institutions/institutions.model';
import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-edit-institution',
  templateUrl: './edit-institution.component.html',
  styleUrls: ['./edit-institution.component.css']
})
export class EditInstitutionComponent implements OnInit, OnDestroy {
  @ViewChild('institutionFormComponent') institutionFormComponent: any;

  private paramsSub!: Subscription;
  private institution!: Institution;
  private institutionId!: string;

  constructor(
    private route: ActivatedRoute,
    private institutionsService: InstitutionsService,
    private router: Router,
    private toastService: ToastService,
  ) {
  }

  public ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.institutionId = params.id;
      this.getInstitution();
    });
  }

  public ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  public saveInstitution(): void {
    this.institutionsService.updateOne(
      Object.assign(this.institution, this.institutionFormComponent.getValues()), this.institution._id
    )
      .then(() => {
        this.toastService.success('Institution bearbeitet', `Die Institution "${this.institution.name}" wurde erfolgreich bearbeitet.`);
        this.router.navigate(['admin/institutions']);
      })
      .catch(error => {
        this.toastService.error('Institution nicht bearbeitet', 'Die Institution konnte nicht bearbeitet werden.', error);
      });
  }

  private getInstitution(): void {
    this.institutionsService.getOne(this.institutionId).then((institution: Institution) => {
      this.institution = institution;
      this.institutionFormComponent.updateValues(this.institution);
    });
  }
}
