import {Router} from '@angular/router';
import {Component, ViewChild} from '@angular/core';

import {InstitutionsService} from '../../../../core/services/institutions/institutions.service';
import {ToastService} from "../../../../core/services/toast/toast.service";

@Component({
  selector: 'app-create-institution',
  templateUrl: './create-institution.component.html',
  styleUrls: ['./create-institution.component.css']
})
export class CreateInstitutionComponent {
  @ViewChild('institutionFormComponent') institutionForm: any;

  constructor(
    private router: Router,
    private institutionsService: InstitutionsService,
    private toastService: ToastService,
  ) {
  }

  public saveInstitution() {
    this.institutionsService.createOne(this.institutionForm.getValues()).then(() => {
      this.toastService.success('Institution erstellt', 'Institution wurde erfolgreich erstellt.');
      this.router.navigate(['/admin/institutions']);
    }).catch(error => {
      this.toastService.error('Institution nicht erstellt!', 'Die Institution konnte nicht erstellt werden.', error);
    });
  }
}
