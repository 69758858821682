<div class="ui grid">
  <div class="row">
    <div class="fifteen wide column">
      <h1 class="ui header">Institutionen verwalten: </h1>
    </div>
    <div class="one wide column">
      <div class="ui right floated left labeled icon primary button" (click)="addInstitution()"
           (keydown)="addInstitution()" tabindex="-1">
        <i class="plus square icon"></i>
        Erstellen
      </div>
    </div>
  </div>
  <div class="row">
    <div class="sixteen wide column">
      <table class="ui selectable celled table">
        <thead>
        <tr>
          <th>Name</th>
          <th>Aktionen</th>
        </tr>
        </thead>
        <tbody>
          @for (inst of institutions; track inst) {
            <tr>
              <td>{{ inst.name }}</td>
              <td>
                <a class="ui primary icon button" data-tooltip="Bearbeiten" [routerLink]="['edit', inst._id]">
                  <i class="edit icon"></i>
                </a>
                <button class="ui red icon button" data-tooltip="Löschen" (click)="delete(inst)">
                  <i class="trash icon"></i>
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
