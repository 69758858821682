import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {
  SuiCheckboxModule,
  SuiCollapseModule,
  SuiPaginationModule,
  SuiPopupModule,
  SuiSelectModule,
  SuiTabsModule,
} from '@angular-ex/semantic-ui';

import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {AdminComponent} from './admin.component';
import {AdminRoutingModule} from './admin-routing.module';
import {SharedFormsModule} from '../../shared/forms/shared-forms.module';
import {AdminMailComponent} from './pages/admin-mail/admin-mail.component';
import {ManageUsersComponent} from './pages/manage-users/manage-users.component';
import {ManageTopicsComponent} from './pages/manage-topics/manage-topics.component';
import {ViewContactsComponent} from './pages/view-contacts/view-contacts.component';
import {SharedComponentsModule} from '../../shared/components/shared-components.module';
import {ManageArticlesComponent} from './pages/manage-articles/manage-articles.component';
import {EditInstitutionComponent} from './pages/edit-institution/edit-institution.component';
import {CreateInstitutionComponent} from './pages/create-institution/create-institution.component';
import {TopicFormComponent} from './pages/manage-topics/components/topic-form/topic-form.component';
import {EditUserModalComponent} from './modals/edit-user-modal/edit-user-modal.component';
import {ManageSubjectareasComponent} from './pages/manage-subjectareas/manage-subjectareas.component';
import {ManageInstitutionsComponent} from './pages/manage-institutions/manage-institutions.component';
import {TopicsListComponent} from './pages/manage-topics/components/topics-list/topics-list.component';

@NgModule({
  declarations: [
    AdminComponent,
    ManageTopicsComponent,
    ManageSubjectareasComponent,
    ManageInstitutionsComponent,
    ManageUsersComponent,
    ManageArticlesComponent,
    CreateInstitutionComponent,
    EditInstitutionComponent,
    EditUserModalComponent,
    ViewContactsComponent,
    AdminMailComponent,
    TopicFormComponent,
    TopicsListComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SuiTabsModule,
    ReactiveFormsModule,
    SuiSelectModule,
    SuiCheckboxModule,
    SuiCollapseModule,
    CKEditorModule,
    SuiPaginationModule,
    SharedComponentsModule,
    FormsModule,
    SharedFormsModule,
    SuiPopupModule,
  ],
  exports: [
    TopicFormComponent,
    TopicsListComponent,
  ],
})
export class AdminModule {
}
