import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {SuiModalService} from '@angular-ex/semantic-ui';

import {ChangeEvent} from '@ckeditor/ckeditor5-angular';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {User} from '../../../../core/models/users/users.model';
import {UsersService} from '../../../../core/services/users/users.service';
import {AdminMail} from '../../../../core/models/admin-mail/admin-mail.model';
import {AlertModal} from '../../../../shared/modals/alert-modal/alert-modal.component';
import {AdminMailsService} from '../../../../core/services/admin-mails/admin-mails.service';

@Component({
  selector: 'app-admin-mail',
  templateUrl: './admin-mail.component.html',
  styleUrls: ['./admin-mail.component.css']
})
export class AdminMailComponent implements OnInit {
  // Data
  public adminMails!: AdminMail[];

  // Form
  public adminMailForm!: UntypedFormGroup;

  // Editor Content
  public Editor = ClassicEditor;
  public initEditorContent!: string;

  // For UI buttons
  public editing = false;

  public pagination = {
    collectionSize: 10,
    pageSize: 10,
    selectedPage: 1,
  };

  public userId!: string;

  constructor(
    private modalService: SuiModalService,
    private adminMailsService: AdminMailsService,
    private usersService: UsersService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.getAllAdminMails();
    this.getCurrentUser();
  }

  public handlePageChange() {
    this.getAllAdminMails();
  }

  edit(adminMail: AdminMail) {
    this.initForm(adminMail);
    this.editing = true;
  }

  delete(adminMail: AdminMail) {
    if (!adminMail._id) {
      return;
    }
    this.modalService.open(new AlertModal(
      `Admin-Mail "${adminMail.title}" löschen?`,
      'Soll diese Admin-Mail wirklich gelöscht werden? Dieser Vorgang kann nicht rückgängig gemacht werden!',
    )).onApprove(() => {
      this.adminMailsService.deleteOne(adminMail._id!).then(() => {
        this.adminMails.splice(this.adminMails.indexOf(adminMail), 1);
      });
    });
  }

  public createOrUpdateAdminMail(update?: boolean) {
    if (this.adminMailForm.valid) {
      if (update) {
        this.adminMailsService.updateOne(this.adminMailForm.get('_id')?.value, this.adminMailForm.value).then(() => {
          this.getAllAdminMails();
          this.resetForm();
          this.editing = false;
        });
      } else {
        if (!this.adminMailForm.get('plannedFor')?.value) {
          this.modalService
            .open(new AlertModal('Admin-Mail sofort senden',
              'Soll diese Admin-Mail sofort versendet werden? Dieser Vorgang kann nicht rückgängig gemacht werden!'))
            .onApprove(() => {
              this.adminMailsService.createOne(this.adminMailForm.value).then(() => {
                this.getAllAdminMails();
                this.resetForm();
              });
            });
          return;
        }

        this.adminMailsService.createOne(this.adminMailForm.value).then(() => {
          this.getAllAdminMails();
          this.resetForm();
        });
      }
    } else {
      this.adminMailForm.markAllAsTouched();
    }
  }

  // set form control value when content of editor changes
  public onChangeDescriptionEditor({editor}: ChangeEvent<ClassicEditor>): void {
    (this.adminMailForm.get('text') as AbstractControl).patchValue(editor.getData());
  }

  public resetForm(): void {
    this.initEditorContent = '';
    this.adminMailForm.reset();
    (this.adminMailForm.get('createdBy') as AbstractControl).patchValue(this.userId);
    this.editing = false;
  }

  private initForm(editMail?: AdminMail): void {
    if (editMail) {
      // Yes the date input needs the yyyy-MM-dd format
      let formattedDate = null;
      if (editMail.plannedFor) {
        editMail.plannedFor = new Date(editMail.plannedFor);
        const year = editMail.plannedFor.getFullYear();
        const month = String(editMail.plannedFor.getMonth() + 1).padStart(2, '0');
        const day = String(editMail.plannedFor.getDate()).padStart(2, '0');
        formattedDate = `${year}-${month}-${day}`;
      }

      this.adminMailForm = this.fb.group({
        _id: [editMail._id],
        title: [editMail.title, Validators.required],
        text: [editMail.text, Validators.required],
        createdBy: [editMail.createdBy],
        plannedFor: [formattedDate ? formattedDate : null],
      });
      this.initEditorContent = editMail.text;
    } else {
      this.adminMailForm = this.fb.group({
        title: ['', Validators.required],
        text: ['', Validators.required],
        createdBy: [this.userId],
        plannedFor: [null],
      });
    }
  }

  private getAllAdminMails() {
    this.adminMailsService.getAll({
      selectedPage: this.pagination.selectedPage,
      pageSize: this.pagination.pageSize,
      populate: 'createdBy',
    }).then((res) => {
      this.adminMails = res.docs;
      this.pagination.collectionSize = res.total;
    });
  }

  private async getCurrentUser() {
    await this.usersService.getCurrentUser().then((user: User) => {
      this.userId = user._id;
      this.initForm();
    });
  }
}
