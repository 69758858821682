<div class="ui grid">
  <div class="row">
    <div class="sixteen wide column">
      <h1 class="ui header">Admin-Mails</h1>
    </div>
  </div>

  <div class="row">
    <div class="sixteen wide column">
      <div class="ui segment">
        <h3>Admin-Mail erstellen/bearbeiten: </h3>
        <div class="ui divider"></div>
        @if (adminMailForm) {
          <div>
            <form class="ui form" [formGroup]="adminMailForm">
              <!--TITLE-->
              <div class="field">
                <label for="title">
                  Titel:*
                </label>
                <input id="title" formControlName="title" type="text" name="title" placeholder="Titel">
              </div>
              <!--TEXT-->
              <div class="field">
                <label for="text">
                  Text:*
                </label>
                <ckeditor id="text" [data]="initEditorContent"
                          [config]="{ language: 'de', toolbar: ['bold', 'italic', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}"
                          [editor]="Editor" (change)="onChangeDescriptionEditor($event)"></ckeditor>
              </div>
              <!--SAVED-->
              <div class="field">
                <label for="plannedFor">Versenden planen am:</label>
                <input id="plannedFor" type="date"
                       formControlName="plannedFor">
              </div>
              <button class="ui secondary button" (click)="resetForm()">Zurücksetzen</button>
              <button class="ui primary button" (click)="createOrUpdateAdminMail()"
                      [disabled]="adminMailForm.invalid || editing">Planen/Senden
              </button>
              <button class="ui primary button" (click)="createOrUpdateAdminMail(true)"
                      [disabled]="adminMailForm.invalid || !editing">Aktualisieren
              </button>
            </form>
          </div>
        }
      </div>
    </div>
  </div>

  <div class="row">
    @if (adminMails && adminMails.length > 0) {
      <div class="sixteen wide column">
        <table class="ui selectable celled table">
          <thead>
          <tr>
            <th>Titel</th>
            <th>Nachricht</th>
            <th>Erstellt von</th>
            <th>Geplant für</th>
            <th>Bereits versendet</th>
            <th>Aktionen</th>
          </tr>
          </thead>
          <tbody>
            @for (adminMail of adminMails; track adminMail) {
              <tr>
                <td>{{ adminMail.title }}</td>
                <td>
                  <div [innerHTML]="adminMail.text"></div>
                </td>
                <td>{{ adminMail.createdBy?.firstname + ' ' + adminMail.createdBy?.lastname }}</td>
                <td>{{ adminMail.plannedFor ? (adminMail.plannedFor | date) : '' }}</td>
                <td>{{ adminMail.sent ? 'Ja' : 'Nein' }}</td>
                <td>
                  <button class="ui primary icon button" data-tooltip="Bearbeiten" (click)="edit(adminMail)">
                    <i class="edit icon"></i>
                  </button>
                  <button class="ui red icon button" data-tooltip="Löschen" (click)="delete(adminMail)">
                    <i class="trash icon"></i>
                  </button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
    @if (adminMails && adminMails.length === 0) {
      <div class="column">
        <p>Aktuell wurden noch keine Admin-Mails geplant oder versendet.</p>
      </div>
    }
  </div>
  <!--PAGINATION-->
  @if (adminMails && adminMails.length > 0) {
    <div class="centered row">
      <sui-pagination [maxSize]="5" [canRotate]="true" [hasEllipses]="true" [collectionSize]="pagination.collectionSize"
                      [pageSize]="pagination.pageSize" [hasNavigationLinks]="true" [hasBoundaryLinks]="true"
                      [(page)]="pagination.selectedPage" (pageChange)="handlePageChange()">
      </sui-pagination>
    </div>
  }
</div>
