import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AdminComponent} from './admin.component';
import {AuthGuard} from '../../core/auth/auth-guard';
import {clientRoles} from '../../core/constants/client-roles.config';
import {AdminMailComponent} from './pages/admin-mail/admin-mail.component';
import {ManageUsersComponent} from './pages/manage-users/manage-users.component';
import {ViewContactsComponent} from './pages/view-contacts/view-contacts.component';
import {ManageTopicsComponent} from './pages/manage-topics/manage-topics.component';
import {ManageArticlesComponent} from './pages/manage-articles/manage-articles.component';
import {EditInstitutionComponent} from './pages/edit-institution/edit-institution.component';
import {CreateInstitutionComponent} from './pages/create-institution/create-institution.component';
import {TopicFormComponent} from './pages/manage-topics/components/topic-form/topic-form.component';
import {ManageSubjectareasComponent} from './pages/manage-subjectareas/manage-subjectareas.component';
import {ManageInstitutionsComponent} from './pages/manage-institutions/manage-institutions.component';
import {EditArticleComponent} from '../author/pages/edit-article/edit-article.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'topics',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    data: {
      expectedRoles: [
        clientRoles.IS_ADMIN,
      ]
    },
    children: [
      {
        path: 'institutions',
        component: ManageInstitutionsComponent
      },
      {
        path: 'institutions/create',
        component: CreateInstitutionComponent
      },
      {
        path: 'institutions/edit/:id',
        component: EditInstitutionComponent
      },
      {
        path: 'users',
        component: ManageUsersComponent
      },
      {
        path: 'topics',
        component: ManageTopicsComponent
      },
      {
        path: 'topics/create',
        component: TopicFormComponent
      },
      {
        path: 'topics/edit/:id',
        component: TopicFormComponent
      },
      {
        path: 'subjectareas',
        component: ManageSubjectareasComponent,
      },
      {
        path: 'articles',
        component: ManageArticlesComponent
      },
      {
        path: 'articles/edit/:id',
        component: EditArticleComponent
      },
      {
        path: 'contacts',
        component: ViewContactsComponent,
      },
      {
        path: 'mailing',
        component: AdminMailComponent,
      },
    ]
  },
  {
    path: '**',
    redirectTo: 'topics',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AdminRoutingModule {
}
